<template>
  <div>
    <div>
      <b-modal
        id="alterar-negativacao"
        no-close-on-backdrop
        ok-title="Alterar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @ok="updateNegativacao"
      >
        <b-row>
          <b-col>
            Remover Negativação
            <v-select
              v-model="statusNegativacao"
              :options="mtNegat.options"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <b-button
      id="toggle-btn"
      class="mb-1"
      variant="primary"
      @click="$router.push({ name: 'financeiro.negativacaocliente' })"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <b-col>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <h5>
                        <strong class="text-primary">Cliente:</strong>
                        {{ infoCliente.nome }}
                      </h5>
                    </b-row>

                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <h5>
                        <strong class="text-primary">CPF:</strong>
                        {{ infoCliente.cpf }}
                      </h5>
                    </b-row>
                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <h5>
                        <strong class="text-primary">Endereço:</strong>
                        {{ infoCliente.endereco }}
                      </h5>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <h5>
                            <strong class="text-primary">Bairro:</strong>
                            {{ infoCliente.bairro }}
                          </h5>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

              </b-col>
            </b-col>
          </b-row>
        </b-card>
        <b-col>
          <b-row align-h="end">
            <b-col class="d-flex justify-content-end">
              <b-button
                v-b-modal.alterar-negativacao
                class="mb-1"
                variant="outline-warning"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Remover Negativação</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-row align-h="between">
              <b-col
                lg="1"
              >
                <b-form-select
                  v-model="perPage"
                  :options="[10, 15, 20]"
                  size="sm"
                />
              </b-col>
              <b-col
                lg="8"
                class="d-flex justify-content-end"
              >
                <b-form-input
                  v-model="filter"
                  placeholder="Pesquisar"
                  type="search"
                  size="sm"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-table
            :items="operacoes"
            :fields="fields"
            :filter="filter"
            hover
            bordered
            striped
            responsive
          >
            <template #head(check)>
              <b-form-checkbox
                v-model="selectAll"
              />
            </template>
            <template #cell(check)="data">
              <b-form-checkbox
                v-model="op"
                :value="data.item.id"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormSelect,
  BButton,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vSelect,
    BCard,
    BTable,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      infoCliente: [],
      id: null,
      operacoes: [],
      op: [],
      mtNegat: {
        options: [],
        id: [],
        selected: '',
      },
      selectAll: false,
      items: [],
      perPage: 10,
      currentPage: 1,
      statusNegativacao: null,
      totalRows: 1,
      filter: '',
      fields: [
        'check',
        { key: 'numero_operacao', label: 'operação', class: 'text-center' },
        { key: 'remessa_id', label: 'id remessa', class: 'text-center' },
        { key: 'vencimento', class: 'text-center' },
        { key: 'valor_nominal', label: 'valor nominal', class: 'text-center' },
        { key: 'negativacao', label: 'status negativação', class: 'text-center' },
      ],
    }
  },
  watch: {
    selectAll(n) {
      n ? this.selectedAll() : this.clearSelect()
    },
  },

  async created() {
    await this.getClienteInfo()
    await this.getOperacoes()

    // LISTAGEM DE MOTIVO NEGATIVAÇÃO
    await axios.get('api/v1/classificacao_negativacao', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    })
      .then(res => {
        // eslint-disable-next-line array-callback-return
        res.data.dados.dados.data.map(item => {
          if (item.id !== 1) {
            this.mtNegat.options.push({
              label: item.nome,
              id: item.id,
            })
          }
        })
      })
  },
  methods: {
    async getClienteInfo() {
      this.infoCliente = []
      await axios.get(`/api/v1/clientes/edit/${this.$route.params.id}`, {
        headers: {
          Accpet: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const dt = res.data.dados.cliente
        this.infoCliente.nome = dt.nome
        this.infoCliente.cpf = this.cpfCNPJ(dt.cpf_cnpj)
        this.infoCliente.endereco = dt.endereco
        this.infoCliente.bairro = dt.bairro
        this.infoCliente.cep = dt.cep
      })
    },
    async getOperacoes() {
      this.operacoes = []
      // OPERAÇÕES DO CLIENTE
      const devId = this.$route.params.id
      await axios.get(`api/v1/operacoes/operacoes_negativados_cliente/${devId}`, {
        headers: {
          Accpet: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        // TABELA
        res.data.dados.map(op => (
          this.operacoes.push({
            cliente_id: op.cliente_id,
            id: op.id,
            numero_operacao: op.numero_operacao,
            valor_nominal: `R$ ${op.valor_nominal}`,
            remessa_id: op.remessa_id,
            vencimento: this.dataHora(op.data_vencimento, true),
            negativacao: op.negativacao,
          })
        ))
      })
      this.totalRows = this.operacoes.length
    },
    clearSelect() {
      this.op = []
    },
    selectedAll() {
      this.op = []
      this.operacoes.map(dt => {
        this.op.push(dt.id)
      })
    },

    // UPDATE DE MOTIVO NEGATIVAÇÃO
    updateNegativacao() {
      if (this.statusNegativacao && this.op.length > 0) {
        const dataDev = {
          classificacao_negativacao_id: this.statusNegativacao.id,
          ops_ids: this.op,
          description: this.statusNegativacao.label,
          motivoId: 51,
        }
        const headers = {
          Accept: 'Application/json',
          headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) },
        }

        axios.put('api/v1/operacoes/update/lote/negativado/operacao/', dataDev, headers).then(res => {
          if (res.status === 200) {
            this.$swal({
              title: 'sucesso',
              text: 'Alteração feita com sucesso',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Erro ao fazer a alteração!',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.getOperacoes()
      this.clearSelect()
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
